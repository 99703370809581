<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Сотрудник
        <v-radio-group class="ml-7" row v-model="role">
          <v-radio
            v-for="(avRole, index) in availableRoles"
            :key="index"
            :value="avRole.role"
            :label="avRole.text"
          >
          </v-radio>
        </v-radio-group>
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->

        <div>
          <FormCardTitle>
            <p class="data__text">Выбрать существующего</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationAutocompleteField
              mask="+7 (9##) ###-##-##"
              label="Телефон"
              validate-name="телефон"
              :disabled="!!phone_number"
              :rules="!!phone_number ? '' : 'required'"
              v-model="selectedId"
              :search-function="searchFunction"
            />
          </FormInputBlock>
        </div>
        <div>
          <FormCardTitle>
            <p class="data__text">Создать нового</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              mask="+7 (9##) ###-##-##"
              label="Телефон"
              :rules="!!selectedId ? '' : 'required|phone|uniquePhone'"
              validate-name="телефон"
              :disabled="!!selectedId"
              v-model="phone_number"
            />

            <div />
            <div />
            <ValidationInputField
              v-model="last_name"
              label="Фамилия"
              validate-name="фамилия"
            />
            <ValidationInputField
              v-model="first_name"
              label="Имя"
              validate-name="имя"
            />
            <ValidationInputField
              v-model="middle_name"
              label="Отчество"
              validate-name="отчество"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(addAdmin)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import { getAllUsersClearRequest } from "../../helpers/api/user";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CreateAdminPage",
  props: {
    serviceOrganizationId: {
      type: [String, Number]
    },
    companyId: {
      type: [String, Number]
    },
    kindergartenId: {
      type: [Number, String]
    },
    dealerId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      availableRoles: [
        {
          text: "Техник",
          role: 40
        }
      ],
      loading: false,
      searchFunction: async value => {
        return (
          await getAllUsersClearRequest({
            query: {
              search: value.replace(/[^\d+а-яА-ЯёЁ ]/g, "").slice(0, 13)
            }
          })
        ).data.results.map(el => ({
          text: `${el.phone_number}, ${el.full_name || ""}` || "",
          value: el.id
        }));
      }
    };
  },
  components: {
    FormCardTitle,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout
  },
  computed: {
    phone_number: {
      get() {
        return this.$store.getters.getAddTechnicianForm.phone_number;
      },
      set(newValue) {
        this.$store.commit("setAddTechnicianFormValue", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    role: {
      get() {
        return this.$store.getters.getAddTechnicianForm.role;
      },
      set(newValue) {
        this.$store.commit("setAddTechnicianFormValue", {
          fieldName: "role",
          value: newValue
        });
      }
    },
    organizationId: {
      get() {
        return this.$store.getters.getAddTechnicianForm.organizationId;
      },
      set(newValue) {
        this.$store.commit("setAddTechnicianFormValue", {
          fieldName: "organizationId",
          value: newValue
        });
      }
    },
    first_name: {
      get() {
        return this.$store.getters.getAddTechnicianForm.first_name;
      },
      set(newValue) {
        this.$store.commit("setAddTechnicianFormValue", {
          fieldName: "first_name",
          value: newValue
        });
      }
    },
    last_name: {
      get() {
        return this.$store.getters.getAddTechnicianForm.last_name;
      },
      set(newValue) {
        this.$store.commit("setAddTechnicianFormValue", {
          fieldName: "last_name",
          value: newValue
        });
      }
    },
    middle_name: {
      get() {
        return this.$store.getters.getAddTechnicianForm.middle_name;
      },
      set(newValue) {
        this.$store.commit("setAddTechnicianFormValue", {
          fieldName: "middle_name",
          value: newValue
        });
      }
    },
    selectedId: {
      get() {
        return this.$store.getters.getAddTechnicianForm.selectedId;
      },
      set(newValue) {
        this.$store.commit("setAddTechnicianFormValue", {
          fieldName: "selectedId",
          value: newValue
        });
      }
    }
  },
  mounted() {
    if (this.serviceOrganizationId)
      this.organizationId = Number(this.serviceOrganizationId);
    if (this.$store.getters.getCurrentRole === 50) {
      this.organizationId = this.$store.getters.getCurrentOrganization;
    }
  },
  methods: {
    addAdmin() {
      this.loading = true;
      this.$store
        .dispatch("addOrganizationTechnician")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.staff)
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearTechnicianForm");
    next();
  }
};
</script>

<style scoped></style>
